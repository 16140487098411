import { notification } from "ant-design-vue";
import axios from "axios";
// import notification from "@/utils/notifications"; // Use this when updated
import { setCallbackConfigAndRedirect } from "../utils/auth";
import store from "../store";

function validateTokenStatus(token) {
  const tokenExpiration = Number(localStorage.getItem("exp"));
  const currentTime = Math.round(Date.parse(Date()) / 1000);
  // confirm token exists and that it is not expired
  if (token && tokenExpiration > currentTime) {
    return true;
  }
  return false;
}

function handleToken(config) {
  const token = localStorage.getItem("access_token");
  if (validateTokenStatus(token)) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

function addOrgIdToMarket(config) {
  const token = localStorage.getItem("access_token");
  if (config.url.includes("/v1/market") && validateTokenStatus(token) && store.getters.getUserType === "ORG") {
    try {
      if ("params" in config) {
        config.params.orgId = store.getters.getOrgId;
      }
      config.params = {
        orgId: store.getters.getOrgId,
      };
    } catch (exception) {
      console.error(exception);
    }
  }
  return config;
}

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_ROOT}`,
});

function handleErrorResponse(error) {
  if (error.response.status === 403) {
    notification.error({
      message: "You don't have permission to do that",
      duration: 4000,
    });
  } else if (error.response.status === 409) {
    notification.error({
      message: "Invalid Token",
      duration: 4000,
    });
  } else if (error.response.status === 404) {
    // ignore 404 for now
  } else if (error.response.status === 401) {
    // Message.error("You are not authenticated, please log in again.");
    // console.log(error.response, "handleErrorResponse")
    // const errorMsgHeader =
    //   error.response && error.response.data ? error.response.data.error || "ERROR!" : error.response.error ? error.response.error : "Event failed due to error!";
    // const unauthorizedMsg = ["invalid_token", "unauthorized"];
    // if (unauthorizedMsg.includes(errorMsgHeader)) {
    // }
  } else if (error.response.status === 422) {
    notification.error({
      message: error.response.data.message,
      duration: 4000,
    });
  }
  return Promise.reject(error);
}
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return handleErrorResponse(error);
  },
);
instance.interceptors.request.use(handleToken);
instance.interceptors.request.use(addOrgIdToMarket);
instance.defaults.headers.post["Content-Type"] = "application/json";

export default instance;

