<template>
  <div class="wrapper flex flex-direction-col justify-center items-center h-full">
    <img src="@/assets/shared/gear-loading.svg" class="loader" />
    <div class="loading-text">
      <h1 class="loading">
        {{ value }}
        <span class="dot-one"> .</span>
        <span class="dot-two"> .</span>
        <span class="dot-three"> .</span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: {
    value: {
      type: String,
      default: () => {
        return "LOADING YOUR SCREEN";
      },
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./LoadingScreen.scss";
</style>
